.nc-MainNav1 {
  &.notOnTop {
    @apply bg-white  backdrop-blur-2xl bg-opacity-70 shadow-sm ;
  }
}

.menu-item.menu-megamenu:hover {
  > .sub-menu {
    @apply visible;
  }
}
